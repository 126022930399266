import { isServer } from './isServer';

const dummyStorageApi = {
  getItem: () => undefined,
  setItem: () => undefined,
  removeItem: () => undefined,
  clear: () => undefined,
};

export const ls = (!isServer ? localStorage : dummyStorageApi) as Storage;
