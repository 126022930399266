import http from '../http';

interface ProductProps {
  category: string;
  description: string;
  disabled: boolean;
  id: number;
  image: string;
  name: string;
}

interface ProductData {
  icon: string;
  id: number;
  name: string;
  products: ProductProps[];
  queue: number;
}

const products = {
  list: async () => {
    const { status, data: result } = await http.get<ProductData[]>('/v3/kds/product/list');

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  listv2: async () => {
    const { status, data: result } = await http.get<ProductData[]>('/v3/kds/product/list');

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result.map((prod) => prod.products).flat();
  },
  update: async (productId: number, updatedData: any) => {
    const { status, data: result } = await http.patch(
      `/v3/kds/product/change/${productId}`,
      updatedData,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
};

export default products;
export type { ProductData, ProductProps };
