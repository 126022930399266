import http from '../http';

export const live = {
  get: async () => {
    const { data, status } = await http.get<string>('/v3/kds/live');
    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
};
