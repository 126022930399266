import http from '../../http';
import { PagedDocs } from '../../types';
import { FurnaceLossDto } from './dto/furnace-loss.dto';
import { FurnaceUpdateDto } from './dto/furnace-update.dto';
import { FurnaceInventoryModel } from './model/furnace-inventory.model';
import { FurnaceLogModel } from './model/furnace-log.model';

export const furnacev2 = {
  find: async () => {
    const { status, data: result } = await http.get<FurnaceInventoryModel[]>(
      '/v1/inventory/furnace-inventory',
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  save: async (dto: FurnaceUpdateDto) => {
    const { status, data: result } = await http.patch('/v1/inventory/furnace-inventory', dto);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  saveMultiple: async (dto: FurnaceUpdateDto[]) => {
    const { status, data: result } = await http.patch(
      '/v1/inventory/furnace-inventory/multiple',
      dto,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  history: async ({ queryKey }: { queryKey: any }) => {
    const [, { product, page }] = queryKey;
    const { status, data: result } = await http.get<PagedDocs<FurnaceLogModel>>(
      `/v1/inventory/furnace-log/${product}/list/${page}`,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }

    return result;
  },
  cooldown: async () => {
    const { status, data: result } = await http.get<number>(
      `/v1/inventory/furnace-inventory/notify`,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }

    return result;
  },
  notify: async () => {
    const { status, data: result } = await http.patch<number>(
      `/v1/inventory/furnace-inventory/notify`,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }

    return result;
  },
  loss: async (dto: FurnaceLossDto) => {
    const { status, data: result } = await http.patch<number>(
      `/v1/inventory/furnace-inventory/loss`,
      dto,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }

    return result;
  },
};
