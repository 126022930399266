import http from '../http';

type CashRegisterAction = 'OPEN' | 'REGISTER' | 'WITHDRAW' | 'DEPOSIT' | 'CLOSE';

interface CashRegisterModel {
  _id: string;
  validated: boolean;
  observation: string;
  value: number;
  action: CashRegisterAction;
  creatorName: string;
  company: string;
  createdAt: Date;
  updatedAt: Date;
  totalCalculatedValue: number;
  spreadValue: number;
  __v: number;
}

interface CashRegisterAddModel {
  observation: string;
  value: number;
  action: CashRegisterAction;
}

interface CashRegisterUpdateModel {
  observation: string;
  value: number;
}

const cashRegister = {
  add: async (data: CashRegisterAddModel) => {
    const { status, data: result } = await http.post('/v3/kds/cash-register/create', data);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  findLastCashRegister: async () => {
    const { status, data: result } = await http.get<CashRegisterModel>(
      '/v3/kds/cash-register/last',
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  listDailyCashRegister: async () => {
    const { status, data: result } = await http.get<CashRegisterModel[]>(
      `/v3/kds/cash-register/daily`,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  find: async ({ queryKey }: { queryKey: any }) => {
    const [, { id }] = queryKey;
    const { status, data: result } = await http.get<CashRegisterModel>(
      `/v3/kds/cash-register/find/${id}`,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  update: async ({ id, data }: { id: any; data: CashRegisterUpdateModel }) => {
    const { status, data: result } = await http.patch(`/v3/kds/cash-register/update/${id}`, data);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
};

export default cashRegister;
export type {
  CashRegisterModel,
  CashRegisterAction,
  CashRegisterAddModel,
  CashRegisterUpdateModel,
};
