import http from '../http';

interface FurnaceElementHistory {
  quantity: number;
  createAt: number;
  changed?: number;
  metadata?: { source?: string; sale_id?: number };
}

interface FurnaceElement {
  id: number;
  image: string;
  name: string;
  quantity: number;
  enabled: boolean;
  lastEnabled: boolean;
  histories: FurnaceElementHistory[];
  changedValue: boolean;
}

interface FurnaceFindModel {
  company: number;
  createAt: number;
  categories: {
    id: number;
    name: string;
    products: FurnaceElement[];
  }[];
  id: string;
  rateLimitNotification: number;
  ttl: number;
  updateAt: number;
}

interface FurnaceSave {
  elements: {
    id: number;
    quantity: number;
  }[];
}

interface FurnaceSaveItem {
  id: number;
  quantity: number;
}

interface FurnaceHistoryModel {
  content: FurnaceElementHistory[];
  totalElements: number;
  totalPages: number;
}

const furnace = {
  find: async () => {
    const { status, data: result } = await http.get<FurnaceFindModel>(
      '/v3/kds/furnace/find/category',
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  history: async ({ queryKey }: { queryKey: any }) => {
    const [, { product, page }] = queryKey;
    const { status, data: result } = await http.get<FurnaceHistoryModel>(
      `/v3/kds/furnace/find/history/${product}/${page}`,
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }

    return result;
  },
  save: async (furnaceStock: FurnaceSave) => {
    const { status, data: result } = await http.patch('/v3/kds/furnace/save', furnaceStock);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  saveItem: async (furnacePayload: FurnaceSaveItem) => {
    const { status, data: result } = await http.patch('/v3/kds/furnace/save/item', {
      ...furnacePayload,
    });
    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  notify: async () => {
    const { status, data: result } = await http.patch('/v3/kds/furnace/notification');

    if (status !== 200) {
      throw new Error((result as Error).message);
    }

    return result;
  },
  loss: async (furnaceStock: FurnaceSave) => {
    const { status, data: result } = await http.patch('/v3/kds/furnace/loss', furnaceStock);

    if (status !== 200) {
      throw new Error((result as Error).message);
    }

    return result;
  },
};

export default furnace;
export type { FurnaceFindModel, FurnaceElement, FurnaceElementHistory };
