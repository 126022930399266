import http from '../http';

interface GoalModel {
  clients: number;
  countClient: number;
  goalClient: number;
  products: number;
  countProducts: number;
  goalProducts: number;
  ticket: number;
  countTicket: number;
  goalTicket: number;
  value: number;
  countValue: number;
  goalValue: number;
}

export const home = {
  goal: async () => {
    const { status, data } = await http.get<{
      day: GoalModel;
    }>('/v3/kds/goal');
    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
};
