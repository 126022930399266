import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { ls } from '../lib/local-storage';
import { STORAGE_AUTH } from './usePin';

const STORAGE_KEY = '@mais1kds/company';

type UseCompanyFieldState = {
  company: number | undefined;
  country: string;
  jwt?: string;
  setCompany: (company: number, country: string) => void;
  setJwt: (token: string) => void;
};

export const useCompany = create<UseCompanyFieldState>()(
  persist(
    (set) => {
      // START REMOVE AFTER MIGRATION
      const oldStorageValue = JSON.parse(ls.getItem('') ?? '{}');
      const oldStorage = oldStorageValue.state ? oldStorageValue.state : oldStorageValue;
      const correctStorageValue = JSON.parse(ls.getItem(STORAGE_KEY) ?? '{}');
      const correctStorage = correctStorageValue.state
        ? correctStorageValue.state
        : correctStorageValue.company
        ? correctStorageValue
        : {};
      const parsedStorage = ls.getItem(STORAGE_AUTH)
        ? oldStorage.company
          ? oldStorage
          : correctStorage
        : {};
      let initialCompany = undefined;
      if (parsedStorage.state) {
        initialCompany = parsedStorage.state.company;
      } else if (parsedStorage.company) {
        initialCompany = parsedStorage.company;
      }
      // FINISH REMOVE AFTER MIGRATION

      return {
        company: +initialCompany,
        country: 'BR',
        setCompany: (company: number, country: string) => set({ company, country }),
        setJwt: (token: string) => set({ jwt: token }),
      };
    },
    {
      name: STORAGE_KEY,
      storage: createJSONStorage(() => ls),
    },
  ),
);
