import http from '../http';

export interface PinProps {
  pin: string;
}

const auth = {
  login: async ({ pin }: PinProps) => {
    const { status, data: result } = await http.get(`/v3/kds/pair/${pin}`);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return {
      apiKey: result.apiKey,
      company: result.company,
      firebaseToken: result.token,
      uid: result.UID,
      country: result.country as 'BR' | 'PY',
      jwt: result['api-token'],
    };
  },
};

export default auth;
