import http from '../http';

export interface CompanyStatusModel {
  id: string;
  name: string;
  merchant: string;
  merchantEnabled: boolean;
  company: number;
  image: string;
  today: number;
  able: boolean;
  opened: boolean;
  lastForceOpen: number;
  forceOpen: boolean;
  completed: boolean;
  completedApp: boolean;
  advice: boolean;
  invalidShift: boolean;
  shifts: ShiftsModel;
  products: any;
  createAt: number;
  updateAt: number;
  ttl: number;
}

export interface ShiftsModel {
  '1': ShiftDayModel;
  '2': ShiftDayModel;
  '3': ShiftDayModel;
  '4': ShiftDayModel;
  '5': ShiftDayModel;
  '6': ShiftDayModel;
  '7': ShiftDayModel;
}

export interface ShiftDayModel {
  first: string;
  second: string;
}

const company = {
  get: async () => {
    const { data, status } = await http.get<CompanyStatusModel>('/v3/kds/company/status');
    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return data;
  },
  open: async () => {
    const { data, status } = await http.patch('/v3/kds/company/open');
    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return status;
  },
  close: async () => {
    const { data, status } = await http.patch('/v3/kds/company/close');
    if (status !== 200) {
      throw new Error((data as any).message);
    }
    return status;
  },
};

export default company;
