import http from '../http';

export interface ExtractionProps {
  barista: string;
  bitterness: string;
  body: string;
  date: string;
  doubleDose: string;
  flavor: string;
  granulometry: string;
  id: number;
  ph: string;
  production: string;
  shift: string;
  simpleDose: string;
  sweetness: string;
  time: string;
  lostCoffee: string;
}

export interface ExtractionData {
  content: ExtractionProps[];
  totalPages: number;
  first: boolean;
  last: boolean;
}

const extraction = {
  list: async (page = 1) => {
    const { status, data: result } = await http.get<ExtractionData>('/v3/kds/extraction/list', {
      params: { page, size: 15 },
    });

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
  create: async (extractionData: ExtractionProps) => {
    const { status, data: result } = await http.post<ExtractionProps>(
      '/v3/kds/extraction/add',
      extractionData,
    );
    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
};

export default extraction;
