import http from '../http';

interface OptionModel {
  id: number;
  image: string;
  name: string;
  price: number;
  disabled: boolean;
}

const options = {
  list: async () => {
    const { status, data: result } = await http.get<{ options: OptionModel[] }>('/v3/kds/options');

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result.options;
  },
  update: async (optionId: number) => {
    const { status, data: result } = await http.patch(`/v3/kds/change/option/${optionId}`);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
};

export type { OptionModel };
export default options;
