import axios from 'axios';
import getConfig from 'next/config';
import { StatusPageModel } from './status-page/model/status-page.model';

const { publicRuntimeConfig } = getConfig();

const http = axios.create({
  baseURL: publicRuntimeConfig.API_URL.replace('/kds', ''),
  validateStatus: (status) => status < 500,
});

const statusPage = {
  find: async () => {
    const result = await http.get<StatusPageModel>('/v3/general/statuspage/');

    if (result.status !== 200) {
      return undefined;
    }
    return result.data;
  },
};

export default statusPage;
