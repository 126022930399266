import http from '../http';

interface AdditionalModel {
  id: number;
  image: string;
  name: string;
  price: number;
  disabled: boolean;
}

const additionals = {
  list: async () => {
    const { status, data: result } = await http.get<{ additionals: AdditionalModel[] }>(
      '/v3/kds/additionals',
    );

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result.additionals;
  },
  update: async (additionalId: number) => {
    const { status, data: result } = await http.patch(`/v3/kds/change/additional/${additionalId}`);

    if (status !== 200) {
      throw new Error((result as any).message);
    }
    return result;
  },
};

export type { AdditionalModel };
export default additionals;
