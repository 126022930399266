import getConfig from 'next/config';
import axios from 'axios';

import { usePin } from '@/hooks/usePin';
import { useCompany } from '../hooks/useCompany';

const { publicRuntimeConfig } = getConfig();

const http = axios.create({
  baseURL: publicRuntimeConfig.API_URL,
  validateStatus: (status) => status < 500,
});

http.interceptors.request.use((config) => {
  const { apiKey, lang } = usePin.getState();
  if (apiKey) config.headers['x-api-key'] = apiKey;
  config.headers['x-language'] = lang;

  if (config.url?.includes('/v1/inventory')) {
    const jwt = useCompany.getState().jwt;

    if (jwt) {
      config.headers['Authorization'] = 'Bearer ' + jwt;
    }

    config.headers['company'] = useCompany.getState()?.company ?? 0;
  } else {
    config.headers['company'] = undefined;
    config.baseURL = publicRuntimeConfig.API_URL;
  }

  return config;
});

export default http;
