import { useTranslation } from 'react-i18next';

import br from '../assets/locale/br.json';

interface LanguageProps {
  en: string;
  br: string;
  es: string;
}

type LanguageKey = keyof typeof br;

type LanguageFunction = (text: LanguageKey) => string;

export function useTranslate() {
  const { t, i18n } = useTranslation();

  function translate(text: LanguageKey) {
    return t(String(text));
  }

  function getTranslation(lang: LanguageProps): string {
    try {
      return lang[i18n.language as keyof LanguageProps];
    } catch {
      return 'N/A';
    }
  }

  return { t: translate, i18n, gt: getTranslation };
}

export type { LanguageProps, LanguageKey, LanguageFunction };
