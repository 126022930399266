import auth from './controllers/auth.controller';
import company from './controllers/company.controller';
import cashRegister from './controllers/cashregister.controller';
import extraction from './controllers/extraction.controller';
import furnace from './controllers/furnace.controller';
import products from './controllers/products.controller';
import additionals from './controllers/additionals.controller';
import sale from './controllers/sale.controller';
import options from './controllers/options.controller';
import { home } from './controllers/home.controller';
import { live } from './controllers/live.controller';
import statusPage from './controllers/status-page.controller';
import { furnacev2 } from './controllers/furnacev2/furnacev2.controller';

const api = {
  auth,
  company,
  cashRegister,
  extraction,
  furnace,
  products,
  options,
  additionals,
  sale,
  home,
  statusPage,
  furnacev2,
  live,
};
export default api;
